/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
const t = ("randomUUID" in crypto);
function n() {
  if (t) return crypto.randomUUID();
  const n = crypto.getRandomValues(new Uint16Array(8));
  n[3] = 4095 & n[3] | 16384, n[4] = 16383 & n[4] | 32768;
  const r = t => n[t].toString(16).padStart(4, "0");
  return r(0) + r(1) + "-" + r(2) + "-" + r(3) + "-" + r(4) + "-" + r(5) + r(6) + r(7);
}
function r() {
  return `{${n().toUpperCase()}}`;
}
function o() {
  return `{${n()}}`;
}
export { r as generateBracedUUID, o as generateLowercaseBracedUUID, n as generateUUID };